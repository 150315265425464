import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    loading: false,
    status: '',
    token: '',
    refreshToken: '',
    user: {},
    loggedIn: '',
    lang: 'vi',
    theme: 'dark'
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};