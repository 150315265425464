import axios from "axios";
import { TokenService } from "./storageService";
import { APP_CONFIG } from "../contants";
import router from "./../router";
const httpClient = axios.create({
  baseURL: APP_CONFIG.apiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const authInterceptor = (config) => {
  config.headers["X-TOKEN"] = TokenService.getMd5();

  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    if (error.response.status === 400 || error.response.status === 401) {
      router.push("/login");
    } else if (error.response.status === 405) {
      router.push("/access-denied");
    } else {
      console.error(error.response.status, error.message);
    }
    return Promise.reject(error);
  }
);

export default httpClient;
