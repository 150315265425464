import { createI18n } from 'vue-i18n'
import vnMessage from './vn.json'
import enMessage from './en.json'
import { TokenService } from '../api/storageService';

const messages = {
  vi: vnMessage,
  en: enMessage,
}

const i18n = createI18n({
  locale: TokenService.getLang(), // set locale
  legacy: false,
  globalInjection: true,
  messages,
  fallbackLocale: TokenService.getLang(),
})

export default i18n

