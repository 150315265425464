const loading = state => state.loading;
const loggedIn = state => state.loggedIn;
const user = state => state.user;
const token = state => state.token;
const lang = state => state.lang;
const theme = state => state.theme;

export default {
    loading,
    loggedIn,
    user,
    token,
    theme,
    lang
};