import moment from "moment";
import { indicators } from "../data/data";
import authService from "@/api/authService";
import { TokenService } from "@/api/storageService";

export default {
  methods: {
    async checkPermission() {
      let result = { requireLogin: true, havePermission: false };
      const response = await authService.checkTokenExpire();
      if (response.status === 200) {
        result.requireLogin =
          response.data !== null ? response.data.Expired : true;
        if (!result.requireLogin) {
          const role = TokenService.getRole();
          if (role === "vip") {
            result.havePermission = true;
          } else {
            result.havePermission = false;
          }
        }
      }

      return result;
    },

    getDateNow: function () {
      let now = new Date();
      let currentDate = now.getDate();
      let currentMonth = now.getMonth() + 1;
      if (now.getDate() < 10) {
        currentDate = "0" + currentDate;
      }
      if (currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }
      const dateNow =
        currentDate + "-" + currentMonth + "-" + now.getFullYear();

      return dateNow;
    },
    getBackDate: function (numOfDate) {
      let now = new Date();
      const dateNow =
        now.getDate() -
        numOfDate +
        "-" +
        parseInt(now.getMonth() + 1) +
        "-" +
        now.getFullYear();

      return dateNow;
    },
    mathRound: function (value, decimal = 2) {
      if (value) {
        return parseFloat(value).toFixed(decimal);
      } else {
        return 0;
      }
    },
    getTimestamp: function (date) {
      if (date) {
        const d = moment().format("YYYY-MM-DD") + "T" + date;
        return parseFloat(moment(d).format("X"));
      } else {
        return "";
      }
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      } else {
        return "";
      }
    },
    formatTime: function (value) {
      if (value) {
        return moment(String(value)).format("HH:mm:ss");
      } else {
        return "";
      }
    },
    formatDay: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM");
      } else {
        return "";
      }
    },
    formatNumber: function (value) {
      if (value == null) return 0;
      let val = (value / 1).toFixed(0).replace(",", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatFloat: function (value, decimal = 2) {
      // let val = (value / 1).toFixed(decimal).replace(",", ",");
      // const result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parseFloat((value / 1).toFixed(decimal));
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    },
    formatPrice: function (
      amount,
      decimalCount = 2,
      decimal = ".",
      thousands = ","
    ) {
      try {
        amount = amount / 1000;
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    },

    getIndicators(lang) {
      let list = [];
      for (let i = 0; i < indicators.length; i++) {
        if (lang == "vi") {
          list.push({ id: indicators[i].id, name: indicators[i].vi });
        } else {
          list.push({ id: indicators[i].id, name: indicators[i].en });
        }
      }
      return list;
    },

    convertData2ChartData(
      dataList,
      timeFieldName,
      valueFieldName,
      timeFormat,
      moneyCoefficient = "1000000000"
    ) {
      const data = [];
      for (let item of dataList) {
        const timeMiniSec = timeFormat
          ? moment(item[timeFieldName], timeFormat).valueOf()
          : moment(item[timeFieldName]).valueOf();
        data.push([timeMiniSec, item[valueFieldName] / moneyCoefficient]);
      }
      return data;
    },

    getUniqueListBy(listData, key) {
      return [...new Map(listData.map((item) => [item[key], item])).values()];
    },

    getWorkingDays(businessDays) {
      var days =
        businessDays +
        Math.floor((Math.min(moment().day(), 5) + businessDays) / 7) * 2;

      return days + 1;
    },

    removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // Remove punctuations
      // Bỏ dấu câu, kí tự đặc biệt
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    },
  },
};
