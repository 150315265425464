import Vuex from 'vuex'
import common from './common';
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    modules: {
        common
    },
})
