import * as types from "./constants";
import { TokenService } from "./../../api/storageService";
import { md5 } from "js-md5";

export default {
  [types.SET_LOADING](state, data) {
    state.loading = data;
  },

  auth_request(state) {
    state.status = "loading";
    state.token = "";
    state.refreshToken = "";
    state.user = "";
  },

  auth_success(state, user) {
    state.status = "logged";
    state.token = user.access_token;
    state.refreshToken = user.refresh_token;
    state.user = user;
    const mdEncrypt = md5(
      user.userid + TokenService.getState() + user.access_token
    );
    TokenService.saveMd5(mdEncrypt.toUpperCase());

    TokenService.saveRole(user.role);
    TokenService.saveUserId(user.userid);
    TokenService.saveUser(user.full_name);
    TokenService.saveUserName(user.user_name);
    TokenService.saveToken(user.access_token);
    TokenService.saveRefreshToken(user.refresh_token);
  },

  auth_error(state) {
    state.status = "error";
    state.token = "";
    state.refreshToken = "";
    state.user = "";
  },

  logged_in(state, loggedIn) {
    state.loggedIn = loggedIn;
  },

  logout(state) {
    state.status = "";
    state.token = "";
    state.refreshToken = "";
    state.user = "";
    state.loggedIn = false;
    TokenService.removeMd5();
    TokenService.removeToken();
    TokenService.removeUserId();
    TokenService.removeRefreshToken();
    TokenService.removeUserName();
    TokenService.removeUser();
    TokenService.removeState();
  },

  set_lang(state, lang) {
    state.lang = lang;
  },

  set_theme(state, theme) {
    state.theme = theme;
  },
};
