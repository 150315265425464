<template>
    <notifications group="success-template"
                :duration="3000"
                :width="300"
                animation-name="v-fade-left"
                position="top right">

        <template #body="props">
            <div class="success-template">
                <div class="success-template-icon">
                    <i class="ri-checkbox-circle-line"></i>
                </div>
                <div class="custom-template-content">
                    <div class="success-template-title">{{props.item.title}}</div>
                    <div class="success-template-text" v-html="props.item.text"></div>
                </div>
                <div class="success-template-close" @click="props.close">
                    <i class="ri-close-line"></i>
                </div>
            </div>
        </template>
    </notifications>
    <notifications group="error-template"
                :duration="3000"
                :width="300"
                animation-name="v-fade-left"
                position="top right">

        <template #body="props">
            <div class="error-template">
                <div class="error-template-icon">
                    <i class="ri-alert-line"></i>
                </div>
                <div class="custom-template-content">
                    <div class="error-template-title">{{props.item.title}}</div>
                    <div class="error-template-text" v-html="props.item.text"></div>
                </div>
                <div class="error-template-close" @click="props.close">
                    <i class="ri-close-line"></i>
                </div>
            </div>
        </template>
    </notifications>
    <notifications group="warning-template"
                :duration="3000"
                :width="350"
                animation-name="v-fade-left"
                position="top right">

        <template #body="props">
            <div class="warning-template">
                <div class="warning-template-icon">
                    <i class="ri-error-warning-line"></i>
                </div>
                <div class="custom-template-content">
                    <div class="warning-template-title">{{props.item.title}}</div>
                    <div class="warning-template-text" v-html="props.item.text"></div>
                </div>
                <div class="warning-template-close" @click="props.close">
                    <i class="ri-close-line"></i>
                </div>
            </div>
        </template>
    </notifications>
    <notifications group="info-template"
                :duration="3000"
                :width="300"
                animation-name="v-fade-left"
                position="top right">

        <template #body="props">
            <div class="info-template">
                <div class="info-template-icon">
                    <i class="ri-information-line"></i>
                </div>
                <div class="custom-template-content">
                    <div class="info-template-title">{{props.item.title}}</div>
                    <div class="info-template-text" v-html="props.item.text"></div>
                </div>
                <div class="info-template-close" @click="props.close">
                    <i class="ri-close-line"></i>
                </div>
            </div>
        </template>
    </notifications>
</template>

<style scoped lang="css">
.warning-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #fff1e0;
    border: 1px solid #fb8c05;
}

.warning-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.warning-template-icon i {
    color: #fb8c05 !important;
    font-size: 32px !important;
}

.warning-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #fb8c05;
}

.warning-template-text {
    color: #fb8c05;
}

.warning-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.warning-template-close i {
    color: #fb8c05;
}

.warning-template-close:hover {
    opacity: 0.8;
}

.info-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #e7f2fd;
    border: 1px solid #2196f3;
}

.info-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.info-template-icon i {
    color: #2196f3 !important;
    font-size: 32px !important;
}

.info-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #2196f3;
}

.info-template-text {
    color: #2196f3;
}

.info-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.info-template-close i {
    color: #2196f3 !important;
}

.info-template-close:hover {
    opacity: 0.8;
}

.error-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #f8d7da;
    border: 1px solid #f5c2c7;
}

.error-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.error-template-icon i {
    color: #842029 !important;
    font-size: 32px !important;
}

.error-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #842029;
}

.error-template-text {
    color: #842029;
}

.error-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.error-template-close i {
    color: #842029 !important;
}

.error-template-close:hover {
    opacity: 0.8;
}

.success-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #d9ffd9;
    border: 1px solid #15C371;
}

.success-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.success-template-icon i {
    color: #15C371 !important;
    font-size: 32px !important;
}

.custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
    font-size: 14px;
}

.success-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #0f5132;
}

.success-template-text {
    color: #0f5132;
}

.success-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.success-template-close i {
    color: #0f5132 !important;
}

.success-template-close:hover {
    opacity: 0.8;
}
</style>