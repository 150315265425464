import { createApp } from "vue";
import * as Moment from "moment";
import * as mTZ from "moment-timezone";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixins/helpers";
import i18n from "./lang/i18n";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./assets/css/element.css";
import "./assets/css/reset.css";
import "./assets/css/sidebar.css";
import "./assets/css/styles.css";
import "./assets/css/components.css";
import "./assets/css/responsive.css";

import Notifications from "@kyvg/vue3-notification";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import hcMore from "highcharts/highcharts-more";
import treemapInit from "highcharts/modules/treemap";
import gaugeInit from "highcharts/modules/solid-gauge";

window.moment = Moment;
mTZ();

Highcharts.setOptions({
  time: {
    timezone: "Asia/Ho_Chi_Minh",
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  credits: {
    enabled: false,
    position: {
      align: "center",
      verticalAlign: "middle",
    },
    text: "ASEANSC SECURITIES",
    href: "",
    style: {
      zIndex: 1000,
      fontSize: "3vh",
      opacity: 0.1,
      color: "var(--text-color)",
    },
  },
  yAxis: {
    gridLineColor: "var(--border-table)",
  },
  chart: {
    // plotBackgroundImage: "./asean_credit.png",
    events: {
      render: function () {
        const chart = this;
        if (!chart.bgImage && !chart.options?.hideBackground) {
          chart.bgImage = chart.renderer
            .image(`${window.location.origin}/asean_credit.png`, 0, 0, 200, 100)
            .add();
        }

        chart.bgImage?.attr({
          x: chart.chartWidth / 2 - 100,
          y: chart.chartHeight / 2 - 50,
        });
      },
    },
  },
});

if (typeof Highcharts === "object") {
  hcMore(Highcharts);
  treemapInit(Highcharts);
  gaugeInit(Highcharts);
}

const app = createApp(App);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(Notifications);
app.use(i18n);
app.use(HighchartsVue);
app.use(store);
app.mixin(mixin);
app.use(router);
app.mount("#app");
export default app;
