<template>
    <router-view />
    <Notifications />
    <Loader />
</template>

<script>
import Notifications from './components/Notifications.vue';
import Loader from './components/Loadding.vue';
import { TokenService } from '@/api/storageService';
import i18n from "@/lang/i18n";

export default {
    components: {
        Notifications,
        Loader
    },
    name: 'App',
    metaInfo: {
        title: 'ASEANSC-Trung tâm phân tích',
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: 'ASEANSC-Trang trung tâm phân tích' },
            { name: 'viewport', content: 'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' }
        ]
    },
    mounted() {
        let theme = TokenService.getTheme() == null ? 'dark' : TokenService.getTheme();
        let lang = TokenService.getLang() == null ? 'vi' : TokenService.getLang();
        this.setLanguage(lang);
        this.setTheme(theme);
    },
    methods: {
        setLanguage(lang) {
            i18n.global.locale.value = lang;
            TokenService.saveLang(lang);
        },

        setTheme(theme) {
            document.querySelector("html").className = theme;
            this.$emit(theme);
            TokenService.saveTheme(theme);
        },
    }
}
</script>

<style lang="css">
@import 'https://cdnjs.cloudflare.com/ajax/libs/remixicon/4.0.0/remixicon.css';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap';
</style>
