const TOKEN_KEY = 'asean_access_token';
const REFRESH_TOKEN_KEY = 'asean_refresh_access_token';
const USER_KEY = 'fullName';
const USERID_KEY = 'userid';
const USERNAME_KEY = 'username';
const PASSWORD_KEY = 'password';
const STATE_KEY = 'state';
const ROLE_KEY = 'role';
const LANG_KEY = 'lang';
const THEME_KEY = 'theme';
const MD5_KEY = 'md5';

const TokenService = {
    getLang() {
        return localStorage.getItem(LANG_KEY)
    },

    saveLang(lang) {
        localStorage.setItem(LANG_KEY, lang)
    },

    getTheme() {
        return localStorage.getItem(THEME_KEY)
    },

    saveTheme(theme) {
        localStorage.setItem(THEME_KEY, theme)
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    },

    getMd5() {
        return localStorage.getItem(MD5_KEY)
    },

    saveMd5(md5) {
        localStorage.setItem(MD5_KEY, md5)
    },

    removeMd5() {
        localStorage.removeItem(MD5_KEY)
    },

    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },

    saveUserName(user){
        localStorage.setItem(USERNAME_KEY, user)
    },

    getUserName() {
        return localStorage.getItem(USERNAME_KEY)
    },

    removeUserName() {
        return localStorage.removeItem(USERNAME_KEY)
    },

    saveUserId(userId){
        localStorage.setItem(USERID_KEY, userId)
    },

    getUserId() {
        return localStorage.getItem(USERID_KEY)
    },

    removeUserId() {
        return localStorage.removeItem(USERID_KEY)
    },


    getState() {
        return localStorage.getItem(STATE_KEY)
    },

    saveState(state){
        localStorage.setItem(STATE_KEY, state)
    },

    removeState() {
        return localStorage.removeItem(STATE_KEY)
    },

    saveUser(user){
        localStorage.setItem(USER_KEY, user)
    },

    getUser() {
        return localStorage.getItem(USER_KEY)
    },

    removeUser() {
        localStorage.removeItem(USER_KEY)
    },

    saveRole(role){
        localStorage.setItem(ROLE_KEY, role)
    },

    getRole() {
        return localStorage.getItem(ROLE_KEY)
    },
}

const LoginInfoService = {
    getUser() {
        return localStorage.getItem(USERNAME_KEY)
    },

    saveUser(username) {
        localStorage.setItem(USERNAME_KEY, username)
    },

    removeUser() {
        localStorage.removeItem(USERNAME_KEY)
    },

    getPassword() {
        return localStorage.getItem(PASSWORD_KEY)
    },

    savePassword(password) {
        localStorage.setItem(PASSWORD_KEY, password)
    },

    removePassword() {
        localStorage.removeItem(PASSWORD_KEY)
    },
}

export { TokenService, LoginInfoService }