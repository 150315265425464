import httpClient  from '../api';

export default {
    login(data) {
        let url = '/api/fss/login';
        return httpClient.post(url, data);
    },

    async checkTokenExpire() {
        let url = "/pbapi/api/CheckTokenExpire";
        return await httpClient.get(url);
    },

    async getPermission() {
        let url = "/pbapi/api/userfunction";
        return await httpClient.get(url);
    },
}