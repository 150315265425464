const allFilters = {
  DVD_PAID_RATE: {
    label: "Tỷ lệ chi trả cổ tức (%)",
    value: "DVD_PAID_RATE",
  },
  DVD_PER_P: {
    label: "Tỷ suất cổ tức (%)",
    value: "DVD_PER_P",
  },
  DVD_1Y: {
    label: "Cổ tức 4 quý (VND)",
    value: "DVD_1Y",
  },
  EPS: {
    label: "EPS 4 quý (VND)",
    value: "EPS",
  },
  PE: {
    label: "PE (lần)",
    value: "PE",
  },
  PB: {
    label: "PB (lần)",
    value: "PB",
  },
  PS: {
    label: "PS (lần)",
    value: "PS",
  },
  EV_PER_EBIT: {
    label: "EV/EBIT (lần)",
    value: "EV_PER_EBIT",
  },
  EV_PER_EBITDA: {
    label: "EV/EBITDA (lần)",
    value: "EV_PER_EBITDA",
  },
  P_PER_FCF: {
    label: "P/FCF (lần)",
    value: "P_PER_FCF",
  },
  TOTAL_VOL: { label: "KLGD hiện tại (CP)", value: "TOTAL_VOL" },
  AVG_VOL_5D: { label: "TB KLGD 5 phiên (CP)", value: "AVG_VOL_5D" },
  AVG_VOL_10D: { label: "TB KLGD 10 phiên (CP)", value: "AVG_VOL_10D" },
  AVG_VOL_20D: { label: "TB KLGD 20 phiên (CP)", value: "AVG_VOL_20D" },
  AVG_VOL_30D: { label: "TB KLGD 30 phiên (CP)", value: "AVG_VOL_30D" },
  AVG_VOL_60D: { label: "TB KLGD 60 phiên (CP)", value: "AVG_VOL_60D" },
  AVG_VOL_YTD: { label: "TB KLGD YTD (CP)", value: "AVG_VOL_YTD" },
  AVG_VOL_1Y: { label: "TB KLGD 1 năm (CP)", value: "AVG_VOL_1Y" },
  TOTAL_VAL: { label: "GTGD hiện tại (Triệu VND)", value: "TOTAL_VAL" },
  AVG_VAL_5D: { label: "TB GTGD 5 phiên (Triệu VND)", value: "AVG_VAL_5D" },
  AVG_VAL_10D: { label: "TB GTGD 10 phiên (Triệu VND)", value: "AVG_VAL_10D" },
  AVG_VAL_20D: { label: "TB GTGD 20 phiên (Triệu VND)", value: "AVG_VAL_20D" },
  AVG_VAL_30D: { label: "TB GTGD 30 phiên (Triệu VND)", value: "AVG_VAL_30D" },
  AVG_VAL_60D: { label: "TB GTGD 60 phiên (Triệu VND)", value: "AVG_VAL_60D" },
  AVG_VAL_YTD: { label: "TB GTGD YTD (Triệu VND)", value: "AVG_VAL_YTD" },
  AVG_VAL_1Y: { label: "TB GTGD 1 năm (Triệu VND)", value: "AVG_VAL_1Y" },
  VOL_PER_5D: {
    label: "KLGD so với KLGD bình quân 5 phiên (lần)",
    value: "VOL_PER_5D",
  },
  VOL_PER_10D: {
    label: "KLGD so với KLGD bình quân 10 phiên (lần)",
    value: "VOL_PER_10D",
  },
  VOL_PER_20D: {
    label: "KLGD so với KLGD bình quân 20 phiên (lần)",
    value: "VOL_PER_20D",
  },
  VOL_10D_VS_3M: {
    label: "KLGD bình quân 10 ngày so với KLGD bình quân 3 tháng (lần)",
    value: "VOL_10D_VS_3M",
  },
  VAL_PER_5D: {
    label: "GTGD so với GTGD bình quân 5 phiên (lần)",
    value: "VAL_PER_5D",
  },
  VAL_PER_10D: {
    label: "GTGD so với GTGD bình quân 10 phiên (lần)",
    value: "VAL_PER_10D",
  },
  VAL_PER_20D: {
    label: "GTGD so với GTGD bình quân 20 phiên (lần)",
    value: "VAL_PER_20D",
  },
  P_PER_20D: { label: "Giá so với MA 20 (%)", value: "P_PER_20D" },
  P_PER_50D: { label: "Giá so với MA 50 (%)", value: "P_PER_50D" },
  P_PER_130D: { label: "Giá so với MA 130 (%)", value: "P_PER_130D" },
  P_PER_200D: { label: "Giá so với MA 200 (%)", value: "P_PER_200D" },
  P_CHANGE_1W: { label: "Thay đổi giá 1 tuần (%)", value: "P_CHANGE_1W" },
  P_CHANGE_1M: { label: "Thay đổi giá 1 tháng (%)", value: "P_CHANGE_1M" },
  P_CHANGE_3M: { label: "Thay đổi giá 3 tháng (%)", value: "P_CHANGE_3M" },
  P_CHANGE_6M: { label: "Thay đổi giá 6 tháng (%)", value: "P_CHANGE_6M" },
  P_CHANGE_1Y: { label: "Thay đổi giá 1 năm (%)", value: "P_CHANGE_1Y" },
  P_PER_MAX_52W: {
    label: "Giá so với giá cao 52 tuần (%)",
    value: "P_PER_MAX_52W",
  },
  P_PER_MIN_52W: {
    label: "Giá so với giá thấp 52 tuần (%)",
    value: "P_PER_MIN_52W",
  },
  BETA30D: { label: "Beta 30 ngày", value: "BETA30D" },
  BETA60D: { label: "Beta 60 ngày", value: "BETA60D" },
  BETA90D: { label: "Beta 90 ngày", value: "BETA90D" },
  RS1W: { label: "Sức mạnh tương đương 1 tuần (%)", value: "RS1W" },
  RS1M: { label: "Sức mạnh tương đương 1 tháng (%)", value: "RS1M" },
  RS3M: { label: "Sức mạnh tương đương 3 tháng (%)", value: "RS3M" },
  RS6M: { label: "Sức mạnh tương đương 6 tháng (%)", value: "RS6M" },
  RS1Y: { label: "Sức mạnh tương đương 1 năm (%)", value: "RS1Y" },
  STD_P_1Y: { label: "Độ lệch chuẩn theo ngày, 1 năm (%)", value: "STD_P_1Y" },
  INVENTORY_TURN: {
    label: "Hệ số quay vòng hàng tồn kho (lần)",
    value: "INVENTORY_TURN",
  },
  INVENTORY_DAYS: {
    label: "Số ngày hàng tồn kho trong kỳ (ngày)",
    value: "INVENTORY_DAYS",
  },
  REC_TURN: {
    label: "Hệ số quay vòng khoản phải thu (lần)",
    value: "REC_TURN",
  },
  REC_DAYS: { label: "Số ngày phải thu trong kỳ (ngày)", value: "REC_DAYS" },
  PAY_TURN: {
    label: "Hệ số quay vòng khoản phải trả (lần)",
    value: "PAY_TURN",
  },
  PAY_DAYS: { label: "Số ngày phải trả trong kỳ (ngày)", value: "PAY_DAYS" },
  CAPITAL_TURN: {
    label: "Hệ số quay vòng vốn lưu động (lần)",
    value: "CAPITAL_TURN",
  },
  FIXED_ASSETS_TURN: {
    label: "Hệ số quay vòng tài sản cố định (lần)",
    value: "FIXED_ASSETS_TURN",
  },
  ASSETS_TURN: {
    label: "Hệ số quay vòng tổng tài sản (lần)",
    value: "ASSETS_TURN",
  },
  ROA: { label: "ROA (%)", value: "ROA" },
  ROE: { label: "ROE (%)", value: "ROE" },
  GR_MARGIN: { label: "Biên lợi nhuận gộp (%)", value: "GR_MARGIN" },
  OPERATING_MARGIN: {
    label: "Biên lợi nhuận hoạt động (%)",
    value: "OPERATING_MARGIN",
  },
  BF_TAX_MARGIN: {
    label: "Biên lợi nhuận trước thuế (%)",
    value: "BF_TAX_MARGIN",
  },
  NET_MARGIN: { label: "Biên lợi nhuận sau thuế (%)", value: "NET_MARGIN" },
  ROIC: { label: "ROIC (%)", value: "ROIC" },
  ROCE: { label: "ROCE (%)", value: "ROCE" },
  DEBT_PER_ASSETS: {
    label: " Chỉ số nợ trên tổng tài sản (lần)",
    value: "DEBT_PER_ASSETS",
  },
  DEBT_PER_CAPITAL: {
    label: " Chỉ số nợ trên tổng nguồn vốn (lần)",
    value: "DEBT_PER_CAPITAL",
  },
  DEBT_PER_EQUITY: {
    label: " Chỉ số nợ trên vốn chủ sở hữu (lần)",
    value: "DEBT_PER_EQUITY",
  },
  LEVERAGE: { label: " Chỉ số đòn bẩy tài chính (lần)", value: "LEVERAGE" },
  INT_COVERAGE: {
    label: " Tỉ lệ đảm bảo chi phí lãi vay (lần)",
    value: "INT_COVERAGE",
  },
  CASH_PER_ASSETS: {
    label: " Chỉ số tiền mặt trên tổng tài sản (lần)",
    value: "CASH_PER_ASSETS",
  },
  FCF_PER_LT_DEBT: {
    label: " Chỉ số FCF trên nợ dài hạn (lần)",
    value: "FCF_PER_LT_DEBT",
  },
  NET_DEBT_PER_EQUITY: {
    label: " Chỉ số nợ ròng trên vốn chủ sở hữu (lần)",
    value: "NET_DEBT_PER_EQUITY",
  },
  NET_DEBT_PER_TANGIBLE_EQUITY: {
    label: " Chỉ số nợ ròng trên vốn chủ sở hữu hữu hình (lần)",
    value: "NET_DEBT_PER_TANGIBLE_EQUITY",
  },
  LT_DEBT_PER_ASSETS: {
    label: " Chỉ số nợ dài hạn trên tổng tài sản (lần)",
    value: "LT_DEBT_PER_ASSETS",
  },
  TANGIBLE_ASSETS_PER_EQUITY: {
    label: " Chỉ số tài sản hữu hình trên vốn chủ sở hữu (lần)",
    value: "TANGIBLE_ASSETS_PER_EQUITY",
  },
  NET_DEBT_PER_MC: {
    label: " Chỉ số nợ ròng trên vốn hóa (lần)",
    value: "NET_DEBT_PER_MC",
  },
  NET_DEBT_PER_TANGIBLE_ASSETS: {
    label: " Chỉ số nợ ròng trên tài sản hữu hình (lần)",
    value: "NET_DEBT_PER_TANGIBLE_ASSETS",
  },
  F_BUY_VALUE: {
    label: "Giá trị mua khối ngoại (triệu VND)",
    value: "F_BUY_VALUE",
  },
  F_SELL_VALUE: {
    label: "Giá trị bán khối ngoại (triệu VND)",
    value: "F_SELL_VALUE",
  },
  F_NET_BUY_VALUE: {
    label: "Giá trị mua ròng khối ngoại (triệu VND)",
    value: "F_NET_BUY_VALUE",
  },
  F_BUY_VAL_PER_5D: {
    label: "GT mua khối ngoại so với TB 5 phiên trước (lần)",
    value: "F_BUY_VAL_PER_5D",
  },
  F_BUY_VAL_PER_10D: {
    label: "GT mua khối ngoại so với TB 10 phiên trước (lần)",
    value: "F_BUY_VAL_PER_10D",
  },
  F_BUY_VAL_PER_20D: {
    label: "GT mua khối ngoại so với TB 20 phiên trước (lần)",
    value: "F_BUY_VAL_PER_20D",
  },
  F_SELL_VAL_PER_5D: {
    label: "GT bán khối ngoại so với TB 5 phiên trước (lần)",
    value: "F_SELL_VAL_PER_5D",
  },
  F_SELL_VAL_PER_10D: {
    label: "GT bán khối ngoại so với TB 10 phiên trước (lần)",
    value: "F_SELL_VAL_PER_10D",
  },
  F_SELL_VAL_PER_20D: {
    label: "GT bán khối ngoại so với TB 20 phiên trước (lần)",
    value: "F_SELL_VAL_PER_20D",
  },
  F_NET_BUY_VOLUME: {
    label: "KL mua ròng khối ngoại (CP)",
    value: "F_NET_BUY_VOLUME",
  },
  AVG_F_NET_BUY_VOL_5D: {
    label: "KL mua ròng khối ngoại TB 5 phiên (CP)",
    value: "AVG_F_NET_BUY_VOL_5D",
  },
  AVG_F_NET_BUY_VOL_10D: {
    label: "KL mua ròng khối ngoại TB 10 phiên (CP)",
    value: "AVG_F_NET_BUY_VOL_10D",
  },
  AVG_F_NET_BUY_VOL_20D: {
    label: "KL mua ròng khối ngoại TB 20 phiên (CP)",
    value: "AVG_F_NET_BUY_VOL_20D",
  },
  AVG_F_NET_BUY_VOL_30D: {
    label: "KL mua ròng khối ngoại TB 30 phiên (CP)",
    value: "AVG_F_NET_BUY_VOL_30D",
  },
  AVG_F_NET_BUY_VOL_60D: {
    label: "KL mua ròng khối ngoại TB 60 phiên (CP)",
    value: "AVG_F_NET_BUY_VOL_60D",
  },
  AVG_F_NET_BUY_VOL_YTD: {
    label: "KL mua ròng khối ngoại TB YTD (CP)",
    value: "AVG_F_NET_BUY_VOL_YTD",
  },
  AVG_F_NET_BUY_VOL_1Y: {
    label: "KL mua ròng khối ngoại TB 1 năm (CP)",
    value: "AVG_F_NET_BUY_VOL_1Y",
  },
  F_NET_BUY_VALUE: {
    label: "GT mua ròng khối ngoại (Triệu VND)",
    value: "F_NET_BUY_VALUE",
  },
  AVG_F_NET_BUY_5D: {
    label: "GT mua ròng khối ngoại TB 5 phiên (Triệu VND)",
    value: "AVG_F_NET_BUY_5D",
  },
  AVG_F_NET_BUY_10D: {
    label: "GT mua ròng khối ngoại TB 10 phiên (Triệu VND)",
    value: "AVG_F_NET_BUY_10D",
  },
  AVG_F_NET_BUY_20D: {
    label: "GT mua ròng khối ngoại TB 20 phiên (Triệu VND)",
    value: "AVG_F_NET_BUY_20D",
  },
  AVG_F_NET_BUY_30D: {
    label: "GT mua ròng khối ngoại TB 30 phiên (Triệu VND)",
    value: "AVG_F_NET_BUY_30D",
  },
  AVG_F_NET_BUY_60D: {
    label: "GT mua ròng khối ngoại TB 60 phiên (Triệu VND)",
    value: "AVG_F_NET_BUY_60D",
  },
  AVG_F_NET_BUY_YTD: {
    label: "GT mua ròng khối ngoại TB YTD (Triệu VND)",
    value: "AVG_F_NET_BUY_YTD",
  },
  AVG_F_NET_BUY_1Y: {
    label: "GT mua ròng khối ngoại TB 1 năm (Triệu VND)",
    value: "AVG_F_NET_BUY_1Y",
  },
  MC: { label: "Vốn hóa (tỷ VND)", value: "MC" },
  EV: { label: "EV (tỷ VND)", value: "EV" },
  M_SCORE: { label: "M-Score", value: "M_SCORE" },
  F_SCORE: { label: "F-Score", value: "F_SCORE" },
  Z_SCORE: { label: "Z-Score", value: "Z_SCORE" },
  C_SCORE: { label: "C-Score", value: "C_SCORE" },
  MAGIC_SCORE: { label: "Magic Formula Score", value: "MAGIC_SCORE" },
  NET_INC_GROWTH: {
    label: "Tăng trưởng LN ròng 4 quý gần nhất so với 4 quý trước (%)",
    value: "NET_INC_GROWTH",
  },
  EPS_GROWTH: {
    label: "Tăng trưởng EPS 4 quý gần nhất so với 4 quý trước (%)",
    value: "EPS_GROWTH",
  },
  REV_GROWTH_1Y: {
    label: "Tăng trưởng doanh thu 4 quý gần nhất so với 4 quý trước (%)",
    value: "REV_GROWTH_1Y",
  },
  DVD_GROWTH: {
    label: "Tăng trưởng DPS 4 quý gần nhất so với 4 quý trước (%)",
    value: "DVD_GROWTH",
  },
  OPERATING_INC_GROWTH: {
    label:
      "Tăng trưởng LN từ hoạt động kinh doanh 4 quý gần nhất so với 4 quý trước (%)",
    value: "OPERATING_INC_GROWTH",
  },
  GR_PROFIT_GROWTH: {
    label: "Tăng trưởng LN gộp 4 quý gần nhất so với 4 quý trước (%)",
    value: "GR_PROFIT_GROWTH",
  },
  NET_INC_GROWTH1: {
    label: "Tăng trưởng LN ròng quý gần nhất so với quý cùng kỳ năm trước (%)",
    value: "NET_INC_GROWTH1",
  },
  REV_GROWTH1: {
    label:
      "Tăng trưởng doanh thu quý gần nhất so với quý cùng kỳ năm trước (%)",
    value: "REV_GROWTH1",
  },
  OPERATING_INC_GROWTH1: {
    label:
      "Tăng trưởng LN từ HĐKD quý gần nhất so với quý cùng kỳ năm trước (%)",
    value: "OPERATING_INC_GROWTH1",
  },
  GR_PROFIT_GROWTH1: {
    label: "Tăng trưởng LN gộp quý gần nhất so với quý cùng kỳ năm trước (%)",
    value: "GR_PROFIT_GROWTH1",
  },
  RSI_14: { label: "RSI (14)", value: "RSI_14" },
  STOCHASTIC_14_3_3: { label: "STOCH (14)", value: "STOCHASTIC_14_3_3" },
  ADI_14: { label: "ADX (14)", value: "ADI_14" },
  STOCHASTIC_RSI_14: { label: "STOCHRSI (14)", value: "STOCHASTIC_RSI_14" },
  ATR_14: { label: "ATR (14)", value: "ATR_14" },
  WILLIAMR_14: { label: "William %R (14)", value: "WILLIAMR_14" },
  MACD_12_26_9: { label: "MACD (12,26,9)", value: "MACD_12_26_9" },
  MFI_14: { label: "MFI (14)", value: "MFI_14" },
  BBAND_20_2: { label: "Bollinger Band %B (20,2)", value: "BBAND_20_2" },
  MA_ENVELOPES_20_25: {
    label: "Moving Average Envelopes (20,2.5) %E",
    value: "MA_ENVELOPES_20_25",
  },
  P_PER_SMA10: { label: "Giá so với SMA (10) (%)", value: "P_PER_SMA10" },
  P_PER_EMA10: { label: "Giá so với EMA (10) (%)", value: "P_PER_EMA10" },
  P_PER_DEMA10: { label: "Giá so với DEMA (10) (%)", value: "P_PER_DEMA10" },
  P_PER_WMA10: { label: "Giá so với WMA (10) (%)", value: "P_PER_WMA10" },
  SMA5_PER_SMA20: {
    label: "SMA (5) so với SMA (20) (%)",
    value: "SMA5_PER_SMA20",
  },
  EMA5_PER_EMA20: {
    label: "EMA (5) so với EMA (20) (%)",
    value: "EMA5_PER_EMA20",
  },
  DEMA5_PER_DEMA20: {
    label: "DEMA (5) so với DEMA (20) (%)",
    value: "DEMA5_PER_DEMA20",
  },
  WMA5_PER_WMA20: {
    label: "WMA (5) so với WMA (20) (%)",
    value: "WMA5_PER_WMA20",
  },
};

const conditionsFilterStock = [
  {
    id: '1',
    label: "Cổ tức",
    conditionsList: [
        {
          label: "Tỷ lệ chi trả cổ tức (%)",
          value: "DVD_PAID_RATE",
        },
        {
          label: "Tỷ suất cổ tức (%)",
          value: "DVD_PER_P",
        },
        {
          label: "Cổ tức 4 quý (VND)",
          value: "DVD_1Y",
        },
        {
          label: "EPS 4 quý (VND)",
          value: "EPS",
        },
    ],
  },
  {
    id: '2',
    label: "Định giá",
    conditionsList: [
      {
        label: "PE (lần)",
        value: "PE",
      },
      {
        label: "PB (lần)",
        value: "PB",
      },
      {
        label: "PS (lần)",
        value: "PS",
      },
      {
        label: "EV/EBIT (lần)",
        value: "EV_PER_EBIT",
      },
      {
        label: "EV/EBITDA (lần)",
        value: "EV_PER_EBITDA",
      },
      {
        label: "P/FCF (lần)",
        value: "P_PER_FCF",
      },
    ],
  },
  {
    id: '3',
    label: "Giá và khối lượng",
    conditionsList: [
      { label: "KLGD hiện tại (CP)", value: "TOTAL_VOL" },
      { label: "TB KLGD 5 phiên (CP)", value: "AVG_VOL_5D" },
      { label: "TB KLGD 10 phiên (CP)", value: "AVG_VOL_10D" },
      { label: "TB KLGD 20 phiên (CP)", value: "AVG_VOL_20D" },
      { label: "TB KLGD 30 phiên (CP)", value: "AVG_VOL_30D" },
      { label: "TB KLGD 60 phiên (CP)", value: "AVG_VOL_60D" },
      { label: "TB KLGD YTD (CP)", value: "AVG_VOL_YTD" },
      { label: "TB KLGD 1 năm (CP)", value: "AVG_VOL_1Y" },
      { label: "GTGD hiện tại (Triệu VND)", value: "TOTAL_VAL" },
      { label: "TB GTGD 5 phiên (Triệu VND)", value: "AVG_VAL_5D" },
      { label: "TB GTGD 10 phiên (Triệu VND)", value: "AVG_VAL_10D" },
      { label: "TB GTGD 20 phiên (Triệu VND)", value: "AVG_VAL_20D" },
      { label: "TB GTGD 30 phiên (Triệu VND)", value: "AVG_VAL_30D" },
      { label: "TB GTGD 60 phiên (Triệu VND)", value: "AVG_VAL_60D" },
      { label: "TB GTGD YTD (Triệu VND)", value: "AVG_VAL_YTD" },
      { label: "TB GTGD 1 năm (Triệu VND)", value: "AVG_VAL_1Y" },
      {
        label: "KLGD so với KLGD bình quân 5 phiên (lần)",
        value: "VOL_PER_5D",
      },
      {
        label: "KLGD so với KLGD bình quân 10 phiên (lần)",
        value: "VOL_PER_10D",
      },
      {
        label: "KLGD so với KLGD bình quân 20 phiên (lần)",
        value: "VOL_PER_20D",
      },
      {
        label: "KLGD bình quân 10 ngày so với KLGD bình quân 3 tháng (lần)",
        value: "VOL_10D_VS_3M",
      },
      {
        label: "GTGD so với GTGD bình quân 5 phiên (lần)",
        value: "VAL_PER_5D",
      },
      {
        label: "GTGD so với GTGD bình quân 10 phiên (lần)",
        value: "VAL_PER_10D",
      },
      {
        label: "GTGD so với GTGD bình quân 20 phiên (lần)",
        value: "VAL_PER_20D",
      },
      { label: "Giá so với MA 20 (%)", value: "P_PER_20D" },
      { label: "Giá so với MA 50 (%)", value: "P_PER_50D" },
      { label: "Giá so với MA 130 (%)", value: "P_PER_130D" },
      { label: "Giá so với MA 200 (%)", value: "P_PER_200D" },
      { label: "Thay đổi giá 1 tuần (%)", value: "P_CHANGE_1W" },
      { label: "Thay đổi giá 1 tháng (%)", value: "P_CHANGE_1M" },
      { label: "Thay đổi giá 3 tháng (%)", value: "P_CHANGE_3M" },
      { label: "Thay đổi giá 6 tháng (%)", value: "P_CHANGE_6M" },
      { label: "Thay đổi giá 1 năm (%)", value: "P_CHANGE_1Y" },
      { label: "Giá so với giá cao 52 tuần (%)", value: "P_PER_MAX_52W" },
      { label: "Giá so với giá thấp 52 tuần (%)", value: "P_PER_MIN_52W" },
      { label: "Beta 30 ngày", value: "BETA30D" },
      { label: "Beta 60 ngày", value: "BETA60D" },
      { label: "Beta 90 ngày", value: "BETA90D" },
      { label: "Sức mạnh tương đương 1 tuần (%)", value: "RS1W" },
      { label: "Sức mạnh tương đương 1 tháng (%)", value: "RS1M" },
      { label: "Sức mạnh tương đương 3 tháng (%)", value: "RS3M" },
      { label: "Sức mạnh tương đương 6 tháng (%)", value: "RS6M" },
      { label: "Sức mạnh tương đương 1 năm (%)", value: "RS1Y" },
      { label: "Độ lệch chuẩn theo ngày, 1 năm (%)", value: "STD_P_1Y" },
    ],
  },
  {
    id: '4',
    label: "Hiệu quả hoạt động",
    conditionsList: [
      { label: "Hệ số quay vòng hàng tồn kho (lần)", value: "INVENTORY_TURN" },
      {
        label: "Số ngày hàng tồn kho trong kỳ (ngày)",
        value: "INVENTORY_DAYS",
      },
      { label: "Hệ số quay vòng khoản phải thu (lần)", value: "REC_TURN" },
      { label: "Số ngày phải thu trong kỳ (ngày)", value: "REC_DAYS" },
      { label: "Hệ số quay vòng khoản phải trả (lần)", value: "PAY_TURN" },
      { label: "Số ngày phải trả trong kỳ (ngày)", value: "PAY_DAYS" },
      { label: "Hệ số quay vòng vốn lưu động (lần)", value: "CAPITAL_TURN" },
      {
        label: "Hệ số quay vòng tài sản cố định (lần)",
        value: "FIXED_ASSETS_TURN",
      },
      { label: "Hệ số quay vòng tổng tài sản (lần)", value: "ASSETS_TURN" },
    ],
  },
  {
    id: '5',
    label: "Khả năng sinh lời",
    conditionsList: [
      { label: "ROA (%)", value: "ROA" },
      { label: "ROE (%)", value: "ROE" },
      { label: "Biên lợi nhuận gộp (%)", value: "GR_MARGIN" },
      { label: "Biên lợi nhuận hoạt động (%)", value: "OPERATING_MARGIN" },
      { label: "Biên lợi nhuận trước thuế (%)", value: "BF_TAX_MARGIN" },
      { label: "Biên lợi nhuận sau thuế (%)", value: "NET_MARGIN" },
      { label: "ROIC (%)", value: "ROIC" },
      { label: "ROCE (%)", value: "ROCE" },
    ],
  },
  {
    id: '6',
    label: "Khả năng thanh toán dài hạn",
    conditionsList: [
      { label: " Chỉ số nợ trên tổng tài sản (lần)", value: "DEBT_PER_ASSETS" },
      {
        label: " Chỉ số nợ trên tổng nguồn vốn (lần)",
        value: "DEBT_PER_CAPITAL",
      },
      {
        label: " Chỉ số nợ trên vốn chủ sở hữu (lần)",
        value: "DEBT_PER_EQUITY",
      },
      { label: " Chỉ số đòn bẩy tài chính (lần)", value: "LEVERAGE" },
      { label: " Tỉ lệ đảm bảo chi phí lãi vay (lần)", value: "INT_COVERAGE" },
      {
        label: " Chỉ số tiền mặt trên tổng tài sản (lần)",
        value: "CASH_PER_ASSETS",
      },
      { label: " Chỉ số FCF trên nợ dài hạn (lần)", value: "FCF_PER_LT_DEBT" },
      {
        label: " Chỉ số nợ ròng trên vốn chủ sở hữu (lần)",
        value: "NET_DEBT_PER_EQUITY",
      },
      {
        label: " Chỉ số nợ ròng trên vốn chủ sở hữu hữu hình (lần)",
        value: "NET_DEBT_PER_TANGIBLE_EQUITY",
      },
      {
        label: " Chỉ số nợ dài hạn trên tổng tài sản (lần)",
        value: "LT_DEBT_PER_ASSETS",
      },
      {
        label: " Chỉ số tài sản hữu hình trên vốn chủ sở hữu (lần)",
        value: "TANGIBLE_ASSETS_PER_EQUITY",
      },
      { label: " Chỉ số nợ ròng trên vốn hóa (lần)", value: "NET_DEBT_PER_MC" },
      {
        label: " Chỉ số nợ ròng trên tài sản hữu hình (lần)",
        value: "NET_DEBT_PER_TANGIBLE_ASSETS",
      },
    ],
  },
  {
    id: '7',
    label: "Khả năng thanh toán ngắn hạn",
    conditionsList: [
      { label: "Chỉ số thanh toán ngắn hạn (lần)", value: "CURR_RATIO" },
      { label: "Chỉ số thanh toán nhanh (lần)", value: "QUICK_RATIO" },
      { label: "Chỉ số thanh toán tiền mặt (lần)", value: "CASH_RATIO" },
      { label: "Vòng quay tiền mặt (lần)", value: "CASH_TURN" },
    ],
  },
  {
    id: '8',
    label: "Khối ngoại",
    conditionsList: [
      { label: "Giá trị mua khối ngoại (triệu VND)", value: "F_BUY_VALUE" },
      { label: "Giá trị bán khối ngoại (triệu VND)", value: "F_SELL_VALUE" },
      {
        label: "Giá trị mua ròng khối ngoại (triệu VND)",
        value: "F_NET_BUY_VALUE",
      },
      {
        label: "GT mua khối ngoại so với TB 5 phiên trước (lần)",
        value: "F_BUY_VAL_PER_5D",
      },
      {
        label: "GT mua khối ngoại so với TB 10 phiên trước (lần)",
        value: "F_BUY_VAL_PER_10D",
      },
      {
        label: "GT mua khối ngoại so với TB 20 phiên trước (lần)",
        value: "F_BUY_VAL_PER_20D",
      },
      {
        label: "GT bán khối ngoại so với TB 5 phiên trước (lần)",
        value: "F_SELL_VAL_PER_5D",
      },
      {
        label: "GT bán khối ngoại so với TB 10 phiên trước (lần)",
        value: "F_SELL_VAL_PER_10D",
      },
      {
        label: "GT bán khối ngoại so với TB 20 phiên trước (lần)",
        value: "F_SELL_VAL_PER_20D",
      },
      { label: "KL mua ròng khối ngoại (CP)", value: "F_NET_BUY_VOLUME" },
      {
        label: "KL mua ròng khối ngoại TB 5 phiên (CP)",
        value: "AVG_F_NET_BUY_VOL_5D",
      },
      {
        label: "KL mua ròng khối ngoại TB 10 phiên (CP)",
        value: "AVG_F_NET_BUY_VOL_10D",
      },
      {
        label: "KL mua ròng khối ngoại TB 20 phiên (CP)",
        value: "AVG_F_NET_BUY_VOL_20D",
      },
      {
        label: "KL mua ròng khối ngoại TB 30 phiên (CP)",
        value: "AVG_F_NET_BUY_VOL_30D",
      },
      {
        label: "KL mua ròng khối ngoại TB 60 phiên (CP)",
        value: "AVG_F_NET_BUY_VOL_60D",
      },
      {
        label: "KL mua ròng khối ngoại TB YTD (CP)",
        value: "AVG_F_NET_BUY_VOL_YTD",
      },
      {
        label: "KL mua ròng khối ngoại TB 1 năm (CP)",
        value: "AVG_F_NET_BUY_VOL_1Y",
      },
      { label: "GT mua ròng khối ngoại (Triệu VND)", value: "F_NET_BUY_VALUE" },
      {
        label: "GT mua ròng khối ngoại TB 5 phiên (Triệu VND)",
        value: "AVG_F_NET_BUY_5D",
      },
      {
        label: "GT mua ròng khối ngoại TB 10 phiên (Triệu VND)",
        value: "AVG_F_NET_BUY_10D",
      },
      {
        label: "GT mua ròng khối ngoại TB 20 phiên (Triệu VND)",
        value: "AVG_F_NET_BUY_20D",
      },
      {
        label: "GT mua ròng khối ngoại TB 30 phiên (Triệu VND)",
        value: "AVG_F_NET_BUY_30D",
      },
      {
        label: "GT mua ròng khối ngoại TB 60 phiên (Triệu VND)",
        value: "AVG_F_NET_BUY_60D",
      },
      {
        label: "GT mua ròng khối ngoại TB YTD (Triệu VND)",
        value: "AVG_F_NET_BUY_YTD",
      },
      {
        label: "GT mua ròng khối ngoại TB 1 năm (Triệu VND)",
        value: "AVG_F_NET_BUY_1Y",
      },
    ],
  },
  {
    id: '9',
    label: "Quy mô",
    conditionsList: [
      { label: "Vốn hóa (tỷ VND)", value: "MC" },
      { label: "EV (tỷ VND)", value: "EV" },
    ],
  },
  {
    id: '10',
    label: "Sức khỏe tài chính",
    conditionsList: [
      { label: "M-Score", value: "M_SCORE" },
      { label: "F-Score", value: "F_SCORE" },
      { label: "Z-Score", value: "Z_SCORE" },
      { label: "C-Score", value: "C_SCORE" },
      { label: "Magic Formula Score", value: "MAGIC_SCORE" },
    ],
  },
  {
    id: '11',
    label: "Tăng trưởng",
    conditionsList: [
      {
        label: "Tăng trưởng LN ròng 4 quý gần nhất so với 4 quý trước (%)",
        value: "NET_INC_GROWTH",
      },
      {
        label: "Tăng trưởng EPS 4 quý gần nhất so với 4 quý trước (%)",
        value: "EPS_GROWTH",
      },
      {
        label: "Tăng trưởng doanh thu 4 quý gần nhất so với 4 quý trước (%)",
        value: "REV_GROWTH_1Y",
      },
      {
        label: "Tăng trưởng DPS 4 quý gần nhất so với 4 quý trước (%)",
        value: "DVD_GROWTH",
      },
      {
        label:
          "Tăng trưởng LN từ hoạt động kinh doanh 4 quý gần nhất so với 4 quý trước (%)",
        value: "OPERATING_INC_GROWTH",
      },
      {
        label: "Tăng trưởng LN gộp 4 quý gần nhất so với 4 quý trước (%)",
        value: "GR_PROFIT_GROWTH",
      },
      {
        label:
          "Tăng trưởng LN ròng quý gần nhất so với quý cùng kỳ năm trước (%)",
        value: "NET_INC_GROWTH1",
      },
      {
        label:
          "Tăng trưởng doanh thu quý gần nhất so với quý cùng kỳ năm trước (%)",
        value: "REV_GROWTH1",
      },
      {
        label:
          "Tăng trưởng LN từ HĐKD quý gần nhất so với quý cùng kỳ năm trước (%)",
        value: "OPERATING_INC_GROWTH1",
      },
      {
        label:
          "Tăng trưởng LN gộp quý gần nhất so với quý cùng kỳ năm trước (%)",
        value: "GR_PROFIT_GROWTH1",
      },
    ],
  },
  {
    id: '12',
    label: "Tín hiệu kỹ thuật",
    conditionsList: [
      { label: "RSI (14)", value: "RSI_14" },
      { label: "STOCH (14)", value: "STOCHASTIC_14_3_3" },
      { label: "ADX (14)", value: "ADI_14" },
      { label: "STOCHRSI (14)", value: "STOCHASTIC_RSI_14" },
      { label: "ATR (14)", value: "ATR_14" },
      { label: "William %R (14)", value: "WILLIAMR_14" },
      { label: "MACD (12,26,9)", value: "MACD_12_26_9" },
      { label: "MFI (14)", value: "MFI_14" },
      { label: "Bollinger Band %B (20,2)", value: "BBAND_20_2" },
      {
        label: "Moving Average Envelopes (20,2.5) %E",
        value: "MA_ENVELOPES_20_25",
      },
      { label: "Giá so với SMA (10) (%)", value: "P_PER_SMA10" },
      { label: "Giá so với EMA (10) (%)", value: "P_PER_EMA10" },
      { label: "Giá so với DEMA (10) (%)", value: "P_PER_DEMA10" },
      { label: "Giá so với WMA (10) (%)", value: "P_PER_WMA10" },
      { label: "SMA (5) so với SMA (20) (%)", value: "SMA5_PER_SMA20" },
      { label: "EMA (5) so với EMA (20) (%)", value: "EMA5_PER_EMA20" },
      { label: "DEMA (5) so với DEMA (20) (%)", value: "DEMA5_PER_DEMA20" },
      { label: "WMA (5) so với WMA (20) (%)", value: "WMA5_PER_WMA20" },
    ],
  },
];

const industries = [
  { label: "Dầu khí", en: 'Oil and Gas', value: "0530" },
  { label: "Hóa chất", en: 'Chemistry', value: "1300" },
  { label: "Tài nguyên cơ bản", en: 'Basic resources', value: "1700" },
  { label: "Xây dựng và vật liệu", en: 'Construction and materials', value: "2300" },
  { label: "Hàng & Dịch vụ Công nghiệp", en: 'Industrial Goods & Services', value: "2700" },
  { label: "Ô tô và phụ tùng", en: 'Automobiles and spare parts', value: "3300" },
  { label: "Thực phẩm và đồ uống", en: 'Food & Beverage', value: "3500" },
  { label: "Hàng cá nhân & Gia dụng", en: 'Personal & Household Goods', value: "3700" },
  { label: "Thiết bị và Dịch vụ Y tế", en: 'Medical Equipment and Services', value: "4530" },
  { label: "Dược phẩm", en: 'Medicine', value: "4570" },
  { label: "Bán lẻ", en: 'Retail', value: "5300" },
  { label: "Truyền thông", en: 'The media', value: "5500" },
  { label: "Du lịch và Giải trí", en: 'Travel and Entertainment', value: "5700" },
  { label: "Viễn thông", en: 'Telecommunication', value: "6000" },
  { label: "Điện", en: 'Electricity', value: "7530" },
  { label: "Nước & Khí đốt", en: 'Water & Gas', value: "7570" },
  { label: "Ngân hàng", en: 'Bank', value: "8300" },
  { label: "Bảo hiểm", en: 'Insurance', value: "8500" },
  { label: "Bất động sản", en: 'Real Estate', value: "8600" },
  { label: "Dịch vụ Tài chính", en: 'Financial services', value: "8700" },
  { label: "Công nghệ Thông tin", en: 'Information technology', value: "9000" },
];

const industries2 = [
  { label: "Sản xuất Dầu khí", en: 'Oil & Gas Producers', value: "0530" },
  { label: "Thiết bị, Dịch vụ và Phân phối Dầu khí", en: 'Oil Equipment, Services & Distribution', value: "0570" },
  { label: "Alternative Energy", en: 'Alternative Energy', value: "0580" },
  { label: "Hóa chất", en: 'Chemicals', value: "1350" },
  { label: "Lâm nghiệp và Giấy", en: 'Forestry & Paper', value: "1730" },
  { label: "Kim loại", en: 'Industrial Metals & Mining', value: "1750" },
  { label: "Khai khoáng", en: 'Mining', value: "1770" },
  { label: "Xây dựng và Vật liệu", en: 'Construction & Materials', value: "2350" },
  { label: "Hàng không & Quốc phòng", en: 'Aerospace & Defense', value: "2710" },
  { label: "Hàng công nghiệp", en: 'General Industrials', value: "2720" },
  { label: "Điện tử & Thiết bị điện", en: 'Electronic & Electrical Equipment', value: "2730" },
  { label: "Công nghiệp nặng", en: 'Industrial Engineering', value: "2750" },
  { label: "Vận tải", en: 'Industrial Transportation', value: "2770" },
  { label: "Tư vấn & Hỗ trợ Kinh doanh", en: 'Support Services', value: "2790" },
  { label: "Ô tô và phụ tùng", en: 'Automobiles & Parts', value: "3350" },
  { label: "Bia và đồ uống", en: 'Beverages', value: "3530" },
  { label: "Sản xuất thực phẩm", en: 'Food Producers', value: "3570" },
  { label: "Hàng gia dụng", en: 'Household Goods & Home Construction', value: "3720" },
  { label: "Hàng hóa giải trí", en: 'Leisure Goods', value: "3740" },
  { label: "Hàng cá nhân", en: 'Personal Goods', value: "3760" },
  { label: "Thuốc lá", en: 'Tobacco', value: "3780" },
  { label: "Thiết bị và Dịch vụ Y tế", en: 'Health Care Equipment & Services', value: "4530" },
  { label: "Dược phẩm", en: 'Pharmaceuticals & Biotechnology', value: "4570" },
  { label: "Phân phối thực phẩm & dược phẩm", en: 'Food & Drug Retailers',  value: "5330" },
  { label: "Bán lẻ", en: 'General Retailers',  value: "5370" },
  { label: "Truyền thông", en: 'Media',  value: "5550" },
  { label: "Du lịch & Giải trí", en: 'Travel & Leisure',  value: "5750" },
  { label: "Viễn thông cố định", en: 'Fixed Line Telecommunications',  value: "6530" },
  { label: "Viễn thông di động", en: 'Mobile Telecommunications',  value: "6570" },
  { label: "Điện", en: 'Electricity',  value: "7530" },
  { label: "Nước & Khí đốt", en: 'Gas, Water & Multi-utilities',  value: "7570" },
  { label: "Ngân hàng", en: 'Banks',  value: "8350" },
  { label: "Bảo hiểm phi nhân thọ", en: 'Nonlife Insurance',  value: "8530" },
  { label: "Bảo hiểm nhân thọ", en: 'Life Insurance',  value: "8570" },
  { label: "Bất động sản", en: 'Real Estate Investment & Services',  value: "8630" },
  { label: "Real Estate Investment Trusts", en: 'Real Estate Investment Trusts',  value: "8670" },
  { label: "Dịch vụ tài chính", en: 'Financial Services',  value: "8770" },
  { label: "Quỹ đầu tư", en: 'Equity Investment Instruments',  value: "8980" },
  { label: "Quỹ đầu tư mạo hiểm", en: 'Venture capital',  value: "8990" },
  { label: "Phần mềm & Dịch vụ Máy tính", en: 'Software & Computer Services',  value: "9530" },
  { label: "Thiết bị và Phần cứng", en: 'Technology Hardware & Equipment',  value: "9570" }
];

const stocksExchange = [
  {
    label: "HOSE",
    value: "HOSE",
  },
  {
    label: "HNX",
    value: "HNX",
  },
  {
    label: "UPCOM",
    value: "UPCOM",
  },
];

const indicators = [
  { id: 1133, vi: "ADX(14)", en: "ADX(14) en en" },
  { id: 1134, vi: "Bollinger Band(20,2)", en: "Bollinger Band(20,2)" },
  { id: 1135, vi: "CCI(14)", en: "CCI(14)" },
  { id: 1149, vi: "ADX(14)", en: "ADX(14)" },
  {
    id: 1150,
    vi: "Đường EMA(20) so với đường EMA(50)",
    en: "Đường EMA(20) so với đường EMA(50)",
  },
  {
    id: 1151,
    vi: "Đường SMA(20) so với đường SMA(50)",
    en: "Đường SMA(20) so với đường SMA(50)",
  },
  {
    id: 1152,
    vi: "Đường SMA(5) so với đường SMA(20)",
    en: "Đường SMA(5) so với đường SMA(20)",
  },
  {
    id: 1141,
    vi: "Giá so với đỉnh đáy 3 tháng",
    en: "Giá so với đỉnh đáy 3 tháng",
  },
  {
    id: 1142,
    vi: "Giá so với đỉnh đáy 6 tháng",
    en: "Giá so với đỉnh đáy 6 tháng",
  },
  { id: 1137, vi: "Giá so với đường EMA(20)", en: "Giá so với đường EMA(20)" },
  { id: 1138, vi: "Giá so với đường EMA(50)", en: "Giá so với đường EMA(50)" },
  { id: 1139, vi: "Giá so với đường SMA(20)", en: "Giá so với đường SMA(20)" },
  { id: 1140, vi: "Giá so với đường SMA(50)", en: "Giá so với đường SMA(50)" },
  { id: 1143, vi: "MACD(12,26,9)", en: "MACD(12,26,9)" },
  { id: 1144, vi: "MFI(14)", en: "MFI(14)" },
  { id: 1145, vi: "RSI(14)", en: "RSI(14)" },
  { id: 1147, vi: "Stochastic(9,6,6)", en: "Stochastic(9,6,6)" },
  { id: 1146, vi: "StochRSI(14)", en: "StochRSI(14)" },
];

//Ngành
const sectors = [
  { id: 1, vi: "Ôtô & linh kiện phụ tùng", en: "Car & auto parts" },
  { id: 2, vi: "Viễn thông", en: "Telecommunications" },
  { id: 3, vi: "Dầu khí", en: "Oil and Gas" },
  { id: 4, vi: "Đồ dùng cá nhân & đồ gia dụng", en: "Personal items & household goods" },
  { id: 5, vi: "Dịch vụ bán lẻ", en: "Retail services" },
  { id: 6, vi: "Dịch vụ tài chính", en: " Financial services" },
  { id: 7, vi: "Hóa chất", en: "Chemicals" },
  { id: 8, vi: "Bất động sản", en: "Real estate" },
  { id: 9, vi: "Dịch vụ tiện ích", en: "Utility services" },
  { id: 10, vi: "Bảo hiểm", en: "Insurance" },
  { id: 11, vi: "Tài nguyên", en: "Resources" },
  { id: 12, vi: "Ngân hàng", en: "Banks" },
  { id: 13, vi: "Thực phẩm & đồ uống", en: "Food & beverages" },
  { id: 14, vi: "Phương tiện truyền thông", en: "Media vehicles" },
  { id: 15, vi: "Công nghệ thông tin", en: "Information Technology" },
  { id: 16, vi: "Du lịch và giải trí", en: "Tourism and Entertainment" },
  { id: 17, vi: "Y tế", en: "Healthcare" },
  { id: 18, vi: "Xây dựng & vật liệu", en: "Construction & Materials" },
  { id: 19, vi: "Hàng hóa & dịch vụ công nghiệp", en: "Goods & Industrial Services" },
];

export {
  allFilters,
  industries,
  indicators,
  stocksExchange,
  conditionsFilterStock,
  sectors,
  industries2
};
