import { createRouter, createWebHistory } from "vue-router";
import { TokenService } from "./../src/api/storageService";
import { uuid } from "vue-uuid";
import { APP_CONFIG } from "@/contants";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("./layouts/default.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "",
        name: "homepage",
        component: () => import("./views/home/index.vue"),
      },
      {
        path: "/lich-kinh-te",
        name: "calendar",
        component: () => import("./views/calendar/Index.vue"),
      },
      {
        path: "/y-tuong",
        name: "idea",
        component: () => import("./views/idea/Index.vue"),
      },
      {
        path: "/thi-truong",
        name: "market",
        component: () => import("./views/market/Index.vue"),
      },
      {
        path: "/kinh-te-vi-mo",
        name: "macro",
        component: () => import("./views/macro/Index.vue"),
      },
      {
        path: "/doanh-nghiep-360",
        name: "enterprise",
        component: () => import("./views/enterprise/Index.vue"),
      },
      {
        path: "/nganh",
        name: "branch",
        component: () => import("./views/branch/cashFlow/Index.vue"),
      },
      {
        path: "/nganh/chi-tiet-nganh",
        name: "branch-detail",
        component: () => import("./views/branch/detail/Index.vue"),
      },
      {
        path: "/hang-hoa",
        name: "goods",
        component: () => import("./views/goods/Index.vue"),
      },
      {
        path: "/dinh-gia",
        name: "valuation",
        component: () => import("./views/valuation/Index.vue"),
      },
    ],
  },
  {
    path: "/trang-chu",
    name: "HomePage",
    component: () => import("./layouts/default.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/tin-hieu",
        name: "signal",
        component: () => import("./views/signal/Index.vue"),
      },
      {
        path: "/phan-tich-ky-thuat",
        name: "analysis",
        component: () => import("./views/analysis/Index.vue"),
      },
      {
        path: "/loc-co-phieu",
        name: "stock-filter",
        component: () => import("./views/stock-filter/Index.vue"),
      },
      {
        path: "/trading-view",
        name: "tradingView",
        component: () => import("./views/trading-view/Index.vue"),
      },
      {
        path: "/chart",
        name: "chart",
        component: () => import("./views/chart/stockChart.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("./layouts/auth.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "callback",
        name: "callback",
        component: () => import("./views/auth/CallBack.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "login",
        name: "login",
        component: () => import("./views/auth/Login.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/doanh-nghiep-360/tong-quan",
        name: "enterprise-overview",
        component: () => import("./views/iframe/overview.vue"),
      },
      {
        path: "/doanh-nghiep-360/danh-gia",
        name: "enterprise-evaluate",
        component: () => import("./views/iframe/evaluate.vue"),
      },
      {
        path: "/doanh-nghiep-360/ho-so",
        name: "enterprise-profile",
        component: () => import("./views/iframe/profile.vue"),
      },
      {
        path: "/doanh-nghiep-360/tai-chinh",
        name: "enterprise-finance",
        component: () => import("./views/iframe/finance.vue"),
      },
      {
        path: "/doanh-nghiep-360/canh-bao-rui-ro",
        name: "enterprise-warning",
        component: () => import("./views/iframe/warning.vue"),
      },
      {
        path: "/doanh-nghiep-360/tin-tuc",
        name: "enterprise-news",
        component: () => import("./views/iframe/news.vue"),
      },
      {
        path: "/doanh-nghiep-360/lich-su-kien",
        name: "enterprise-event",
        component: () => import("./views/iframe/event.vue"),
      },
      {
        path: "/doanh-nghiep-360/dn-cung-nganh",
        name: "enterprise-company",
        component: () => import("./views/iframe/company.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (TokenService.getMd5() != "" && TokenService.getMd5() != null) {
      next();
      return;
    } else {
      // next('/login')
      const state = uuid.v1();
      const url =
        APP_CONFIG.ssoUrl +
        "/sso/oauth/authorize?client_id=" +
        APP_CONFIG.ssoClientId +
        "&response_type=code&redirect_uri=" +
        APP_CONFIG.webUrl +
        "/callback&scope=general&state=" +
        state +
        "&ui_locales=vi&loginWithAnother=true&webtrade=true&language=vi&name=&username=&theme=dark";
      window.location.href = url;
    }
  } else {
    next();
  }
});
export default router;
